import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Utils
import HocUtil from 'Utils/hoc-util';
import { didBacktrack } from 'Utils/history';

// Actions
import { setDidScrollToBottom } from '../redux/actions/app';

// Components
import withHistory from 'Webapp/shared/app/hocs/withHistory';

function withScrollToBottomTracking(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        didTrackScrollToBottom: false,
      };

      this.trackScrolledToBottom = this.trackScrolledToBottom.bind(this);
    }

    trackScrolledToBottom() {
      if (
        !didBacktrack(this.props.history) &&
        !this.state.didTrackScrollToBottom
      ) {
        this.setState({ didTrackScrollToBottom: true }, () => {
          this.props.onScrolledToBottom();
          this.props.setDidScrollToBottom(true);
        });
      }
    }

    render() {
      return (
        <Component
          {...this.props}
          onScrolledToBottom={this.trackScrolledToBottom}
        />
      );
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    history: PropTypes.object.isRequired,
    onScrolledToBottom: PropTypes.func.isRequired,
    setDidScrollToBottom: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setDidScrollToBottom }, dispatch);

  return connect(null, mapDispatchToProps)(withHistory(WrappedComponent));
}

export default withScrollToBottomTracking;
