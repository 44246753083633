import React from 'react';
import PropTypes from 'prop-types';

// Components
import withScrollToBottomTracking from '../hocs/withScrollToBottomTracking';
import Visible from './visible';

function VisibilityScrollToBottomTracker(props) {
  return (
    <Visible {...props} waitUntilMoved>
      {(isVisible) => isVisible && props.onScrolledToBottom()}
    </Visible>
  );
}

VisibilityScrollToBottomTracker.propTypes = {
  onScrolledToBottom: PropTypes.func.isRequired,
};

export default withScrollToBottomTracking(VisibilityScrollToBottomTracker);
