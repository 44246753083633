import React from 'react';
import PropTypes from 'prop-types';
import withT from 'ComponentLibrary/hocs/withT';

function NoItems({ t }) {
  return <div className="no-items">{t('no_items')}</div>;
}

NoItems.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withT(NoItems);
