import React from 'react';
import classNames from 'classnames';

// Libs
import SectionUtil from 'Utils/content/section-util';

// Views
import NoItems from './no-items';

// Components
import Item, { ITEM_DISPLAY_TYPES } from '../../components/item';
import { CondensedItemModifiersType } from '../../components/item/item-condensed';

import connector from 'Utils/connector';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectIsArticleRoute, {
  ConnectIsArticleRouteProps,
} from 'Webapp/shared/app/connectors/connectIsArticleRoute';
import connectCurrentUser, {
  ConnectCurrentUserProps,
} from 'Webapp/shared/app/connectors/connectCurrentUser';

interface ItemListPassedProps {
  className: string;
  section: Flipboard.Section;
  items: Array<Flipboard.Item>;
  isLargeScreen: boolean;
  preventExcerpt?: boolean;
  truncateTitleLength?: number;
  displayType?: string;
  condensedItemModifiers?: Array<CondensedItemModifiersType>;
  styleModifier?: typeof ITEM_LIST_STYLE_MODIFIERS;
  limit?: number | null;
}

type ItemListProps = ConnectResponsiveProps &
  ConnectCurrentUserProps &
  ConnectIsArticleRouteProps &
  ItemListPassedProps;

const ItemList: React.FC<ItemListProps> = ({
  className,
  section,
  items,
  isLargeScreen,
  preventExcerpt = false,
  truncateTitleLength = 120,
  displayType = ITEM_DISPLAY_TYPES.CARD,
  isArticleRoute,
  condensedItemModifiers,
  styleModifier = ITEM_LIST_STYLE_MODIFIERS.DEFAULT,
  limit,
  currentUserData,
}) => {
  if (!SectionUtil.hasItems(section, items)) {
    return <NoItems />;
  }

  let itemsToRender = SectionUtil.itemsToRender(
    section,
    items,
    currentUserData,
  );
  if (limit) {
    itemsToRender = itemsToRender.slice(0, limit);
  }

  const showExcerpt = isArticleRoute || (!preventExcerpt && isLargeScreen);

  const listClassName = classNames(className, 'item-list', {
    'item-list--grid': styleModifier === ITEM_LIST_STYLE_MODIFIERS.GRID,
    'item-list--related-context':
      styleModifier === ITEM_LIST_STYLE_MODIFIERS.RELATED,
  });

  return (
    <ul className={listClassName}>
      {itemsToRender.map((item, index) => (
        <li className="item-list__item" key={`${index}-${item.id}`}>
          <Item
            item={item}
            section={section}
            shownInList
            truncateTitleLength={truncateTitleLength}
            truncateExcerptLength={200}
            displayType={displayType}
            condensedItemModifiers={condensedItemModifiers}
            showExcerpt={showExcerpt}
          />
        </li>
      ))}
    </ul>
  );
};

export const ITEM_LIST_STYLE_MODIFIERS = {
  DEFAULT: 'default',
  GRID: 'grid',
  RELATED: 'related',
};

export default connector<ItemListProps>(
  connectIsArticleRoute,
  connectResponsive,
  connectCurrentUser,
)(ItemList);
