import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';

import { getSocialActivity } from 'Webapp/shared/concepts/social-activity';
import { BODY_TYPES } from 'Style/typography';
import { TEXT_COLORS } from 'Style/colors';
// Components
import SocialActions from '../social-actions';
import Bullet from 'Webapp/shared/app/components/bullet';
import { BUTTON_SETS } from 'Webapp/shared/app/hocs/withItemActions';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectSocialActivity from 'Webapp/shared/app/connectors/connectSocialActivity';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

export const StyledMetrics = styled.div(BODY_TYPES.XSMALL_STANDARD, {
  color: TEXT_COLORS.primary,
});

function ItemSocialActions({
  className,
  item,
  section,
  t,
  hideCounts,
  hideMeatball,
  isAnalytics,
  buttonSet,
  actionSheetButtonSet,
  socialActivity,
  featureFlags,
}) {
  const counts = [];
  if (!hideCounts && item) {
    const {
      global: { flipCount, likeCount, shareCount },
    } = getSocialActivity(item.flipboardSocialId, socialActivity);
    if (flipCount) {
      counts.push(t('flip_count', { count: flipCount }));
    }
    if (likeCount) {
      counts.push(t('like_count', { count: likeCount }));
    }
    if (shareCount) {
      counts.push(t('share_count', { count: shareCount }));
    }
  }

  const renderMetrics = () => (
    <StyledMetrics>
      {counts.reduce((a, c, i) => {
        if (a === null) {
          return [c];
        }
        return [...a, <Bullet key={i} />, c];
      }, null)}
    </StyledMetrics>
  );

  if (section && section.isNglPromoted) {
    return null;
  }
  const SocialActionsComponent = (
    <SocialActions
      item={item}
      section={section}
      // shouldShowTime={false}
      hideMeatball={hideMeatball}
      isAnalytics={isAnalytics}
      buttonSet={buttonSet}
      actionSheetButtonSet={actionSheetButtonSet}
      showSocialMetrics
    />
  );

  const showCounts =
    !featureFlags.COMMENTS && !featureFlags.ITEM_METRIC_DETAIL && !hideCounts;
  return (
    <div
      className={classNames(className, { 'item-social-actions': showCounts })}
    >
      {showCounts && renderMetrics()}
      {SocialActionsComponent}
    </div>
  );
}

ItemSocialActions.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  t: PropTypes.func.isRequired,
  socialActivity: PropTypes.object.isRequired,
  hideCounts: PropTypes.bool,
  hideMeatball: PropTypes.bool,
  isAnalytics: PropTypes.bool,
  buttonSet: PropTypes.array,
  actionSheetButtonSet: PropTypes.array,
  featureFlags: PropTypes.object.isRequired,
};

ItemSocialActions.defaultProps = {
  section: null,
  hideCounts: false,
  hideSocialButtons: false,
  isAnalytics: false,
  actionSheetButtonSet: BUTTON_SETS.DEFAULT_ACTION_SHEET,
};

export default connector(
  connectSocialActivity,
  connectFeatureFlags,
)(withT(ItemSocialActions));
