import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_SUBHEADING_TYPES } from 'Style/typography';

// Components
import SectionLink from 'Webapp/shared/app/components/section-link';
import ItemList, {
  ITEM_LIST_STYLE_MODIFIERS,
} from 'Webapp/shared/app/views/section/item-list';
import { ITEM_DISPLAY_TYPES } from 'Webapp/shared/app/components/item';
import withT from 'ComponentLibrary/hocs/withT';
import SectionTag, {
  STYLE_VARIATIONS as TOPIC_TAG_STYLE_VARIATIONS,
} from '../components/section-tag';

// // Libs
import sentenceCase from 'Utils/content/sentence-case';
import replaceTags from 'Utils/i18n/replace-tags';

const ContextSectionHeader = styled.h2({
  ...UI_SUBHEADING_TYPES.LARGE_STANDARD,
  marginBottom: SPACING.LARGE,
  '.topic-tag': { padding: '0' },
});

const HeaderSectionLink = styled(SectionLink)({
  wordBreak: 'break-all',
});

const ContextSection = ({
  className,
  section,
  itemsOverride,
  notSameItemFilter,
  headerOverride,
  seeMoreActionOverride,
  hideSeeMore,
  condensedItems,
  t,
  sectionItemLimit,
  truncateItemTitleLength,
  condensedItemModifiers,
}) => {
  const items = itemsOverride || section.items;
  // // TODO: re-evaluate whether this is still the case?
  // // Bail on rendering if we don't have enough items for the design
  // if (section.items && section.items.length < sectionItemLimit) {
  //   return null;
  // }

  const defaultHeaderContent = (
    <React.Fragment>
      {replaceTags(
        t('more_stories_from', {
          sectionName: section.title,
        }),
        {
          sectionLink: function SectionLinkWrapper(title) {
            return section.isTopic ? (
              <SectionTag
                section={section}
                styleVariation={TOPIC_TAG_STYLE_VARIATIONS.CAPITALIZE_COLOR}
              />
            ) : (
              <HeaderSectionLink
                className="more-stories__see-more"
                section={section}
              >
                {title}
              </HeaderSectionLink>
            );
          },
        },
      )}
    </React.Fragment>
  );
  return (
    <section className={classNames(className, 'article__section')}>
      <div>
        <ContextSectionHeader>
          {headerOverride ? headerOverride : defaultHeaderContent}
        </ContextSectionHeader>
        <ItemList
          section={section}
          items={items.filter(notSameItemFilter)}
          limit={sectionItemLimit}
          truncateTitleLength={truncateItemTitleLength}
          displayType={
            condensedItems
              ? ITEM_DISPLAY_TYPES.CONDENSED
              : ITEM_DISPLAY_TYPES.CARD
          }
          styleModifier={ITEM_LIST_STYLE_MODIFIERS.RELATED}
          condensedItemModifiers={condensedItemModifiers}
        />
        {!hideSeeMore && (
          <div className="article__section-footer article__section-footer--see-more">
            {seeMoreActionOverride || (
              <SectionLink
                className="more-stories__see-more  ui-text--supporting-emphasis"
                section={section}
              >
                {section.isTopic
                  ? replaceTags(
                      t('see_more_stories_in', {
                        sectionName: section.title,
                      }),
                      {
                        sectionLink: function SectionLinkWrapper() {
                          return (
                            <SectionTag
                              section={section}
                              styleVariation={
                                TOPIC_TAG_STYLE_VARIATIONS.CAPITALIZE_COLOR
                              }
                              isClickable={false}
                            />
                          );
                        },
                      },
                    )
                  : sentenceCase(t('see_more'))}
              </SectionLink>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

ContextSection.propTypes = {
  className: PropTypes.string,
  section: PropTypes.object.isRequired,
  itemsOverride: PropTypes.array,
  sectionItemLimit: PropTypes.number.isRequired,
  notSameItemFilter: PropTypes.func,
  headerOverride: PropTypes.node,
  hideSeeMore: PropTypes.bool,
  condensedItems: PropTypes.bool,
  truncateItemTitleLength: PropTypes.number,
  t: PropTypes.func.isRequired,
  seeMoreActionOverride: PropTypes.object,
  condensedItemModifiers: PropTypes.arrayOf(PropTypes.string),
};

ContextSection.defaultProps = {
  notSameItemFilter: (_i) => true,
  headerOverride: null,
  hideSeeMore: false,
  condensedItems: false,
  truncateItemTitleLength: 50,
  seeMoreActionOverride: null,
  condensedItemModifiers: [],
};

export default withT(ContextSection);
